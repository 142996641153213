import React from 'react'

function Icon(props) {
	return (
		<svg
			className="icon"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
			{...props}
		>
			<g fill="none" stroke="currentColor" fillRule="evenodd">
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M2.525 20.225V8.675C2.525 6.24495 4.49495 4.275 6.925 4.275H14.625C17.0551 4.275 19.025 6.24495 19.025 8.675V13.075"
						stroke-linecap="round"
					/>
					<path
						d="M21.775 18.575H21.225C20.01 18.575 19.025 17.59 19.025 16.375V15.825"
						stroke-linecap="round"
					/>
					<path
						d="M5.275 18.025C5.275 18.025 7.475 18.575 9.675 18.575C11.875 18.575 13.525 18.025 13.525 18.025"
						stroke-linecap="square"
					/>
					<path d="M16.275 15.275V20.225" stroke-linecap="round" />
					<path
						d="M23.5 14V14C22.7511 14.8127 21.6963 15.275 20.5911 15.275H14.625C9.65381 15.275 7.82787 11.6804 8.33496 7.74022C8.4316 6.98928 9.1059 6.475 9.86303 6.475C11.0557 6.475 12.425 6.475 12.425 6.475"
						stroke-linecap="round"
					/>
					<path
						d="M16.825 13.075L14.625 10.875"
						stroke-linecap="round"
					/>
					<path
						d="M14.625 13.075L16.825 10.875"
						stroke-linecap="round"
					/>
				</svg>
			</g>
		</svg>
	)
}

const MemoIcon = React.memo(Icon)
export default MemoIcon
