import React from 'react'

function Icon(props) {
	return (
		<svg
			className="icon"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
			{...props}
		>
			<g fill="none" stroke="currentColor" fillRule="evenodd">
				<path
					d="M2.11071 4.97445C2.11071 1.67445 8.16071 2.77445 12.0107 9.92445C15.8607 2.77445 21.8995 1.67445 21.8995 4.97445C21.8995 8.27445 22.4495 13.2244 16.3995 14.3244C19.6995 15.9744 19.6995 17.6244 18.0495 19.5519C15.8495 22.1219 13.6719 20.7971 12.0107 16.5244C10.3495 20.7971 8.16071 22.1219 5.96071 19.5519C4.31071 17.6244 4.31071 15.9744 7.61071 14.3244C1.56071 13.2244 2.11071 8.27445 2.11071 4.97445Z"
					stroke-linecap="round"
				/>
			</g>
		</svg>
	)
}

const MemoIcon = React.memo(Icon)
export default MemoIcon
