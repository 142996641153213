import React from 'react'

function Icon(props) {
	return (
		<svg
			className="icon"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
			{...props}
		>
			<mask
				id="mask0_801_107"
				maskUnits="userSpaceOnUse"
				x={3}
				y={3}
				width={18}
				height={18}
			>
				<rect x={3} y={3} width={18} height={18} fill="white" />
			</mask>

			<g
				mask="url(#mask0_801_107)"
				fill="none"
				stroke="currentColor"
				fillRule="evenodd"
			>
				<circle cx={6.75} cy={17.25} r={3} stroke-linecap="round" />
				<circle cx={7.5} cy={16.5} r={7.5} stroke-linecap="round" />
				<circle cx={8.25} cy={15.75} r={12} stroke-linecap="round" />
			</g>
		</svg>
	)
}

const MemoIcon = React.memo(Icon)
export default MemoIcon
